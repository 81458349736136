import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { HeadingSecondary } from '../Styles/headers'
import { theme } from '../Styles/theme'
import { Section } from "../Styles/section"
import OptinFreeJsCourseGif from './../images/optin-freejscourse.gif'

const gifWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const optinGif = css`
  width: 50%;
  max-width: 380px;
  border: 2px solid #f4f4f4;
`

export default () => {
  return (
    <div>
      <HeadingSecondary
        theme={theme}
        style={{ margin: "5rem 0 3rem", textAlign: "center", fontFamily: "Source sans pro" }}
      >
      All set!
      </HeadingSecondary>
      <p style={{ fontFamily: "Source sans pro", textAlign: "center" }}>
        Keep an eye on your inbox. 
      </p>
      <div css={gifWrapper}>
        <img
          css={optinGif}
          src="https://media.giphy.com/media/yidUzkciDTniZ7OHte/giphy.gif"
          alt="optin confirmed"
        ></img>
      </div>
    </div>
  )
}